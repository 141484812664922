import React, {useState} from 'react'
import * as Bs from "react-bootstrap"
import styled from "styled-components"
import firebase from 'gatsby-plugin-firebase'
import {navigate} from 'gatsby'

const Form = styled.form`

  margin: 0 auto;
`

const Input = styled.input`
  display: block;
  padding: 14px;
  font-size: 1rem;
  margin-bottom: 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: none;
  height: 60px;
  width: 100%;
  &:focus, &:active {
    border: 1px solid rebeccapurple;
  }
`



function Hero(props) {

  // let source = source
  // let to = to
 

  const [formValues, setFormValues] = useState({
    name: '',
    telephone: '',
    email: '',
    message: '',
    error: null,
  })


  async function addToDB() {
    let theSubject = props.content.theSubject
    let theMessage = `${props.content.field1}: ${formValues.name} <br>${props.content.field5}:<br>${props.content.field2}: ${formValues.telephone}<br>${props.content.field3}: ${formValues.email}<br>${props.content.field4}: ${formValues.message}`

    await firebase.firestore().collection('contacts').add({
      source: props.content.source,
      to: props.content.to,
      name: formValues.name,
      telephone: formValues.telephone,
      email: formValues.email,
      message: {
        subject: theSubject,
        html: theMessage,
      }
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
  });
    console.log('it worked!!')
  }
  
  const handleSubmit = async e => {
    e.preventDefault()
    addToDB()
    navigate(props.defaults.data.thankYou)
    console.log(`I am sending a ${formValues.name}`)
  }

  function handleInputChange(e) {
    e.persist();
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value
    }))
  }
 
  return (
       <Form onSubmit={handleSubmit}>
         <Input name="name" placeholder={props.content.field1} onChange={handleInputChange} type="text"/>
         <Input name="telephone" placeholder={props.content.field2} onChange={handleInputChange} type="text"/>
         <Input name="email" placeholder={props.content.field3} onChange={handleInputChange} type="text"/>
         <Bs.Form.Control className="input" name="message" placeholder={props.content.field4} onChange={handleInputChange} as="textarea" rows={3} />
         <Bs.Button className="btn-lg btn" id='buttonHover' type="submit" style={{
           width: '100%', marginTop: 20, height: 50,
           fontWeight: 700, fontFamily: 'Montserrat', paddingTop: 10, background: props.design.buttonColor, borderColor: props.design.buttonBorder, color: props.design.buttonFontColor

           }}>{props.content.button}</Bs.Button>
       </Form>

  )

}

export default Hero