import React from 'react'
import { Container, Row, Col} from "react-bootstrap";
import FormContact from "../Blocks/FormContact"
import GoogleMaps from "../Blocks/MapGoogle"
import { TextBlock } from "../Core";
import Seo from '../seo'
import Breadcrumbs from "../Blocks/Breadcrumbs"
function Contact(props) {


    return (
        <>
            <Seo
                title={props.content.seoTitle || props.content.h1}
                description={props.content.seoDescription}
                metas={props.global.defaults.metas}
            />
            <Breadcrumbs home={props.global.defaults.lang.home} contentH1={props.content.h1}/>
            <Container style={{maxWidth: 1270}}>
         
            <Row>
            <Col sm={6} data-aos="fade-right" id='mapLeft'>
            <div id='h1p'>
                    <h1 style={{textAlign: 'left'}}>{props.content.h1}</h1>
                    <p style={{textAlign: 'left'}}>{props.content.subTitle || ''}</p>
                </div>
                <TextBlock>
                <div style={{marginTop: 30}} dangerouslySetInnerHTML={{ __html: props.content.text}} />

            
                 <p style={{textAlign: 'left'}}>{props.global.defaults.lang.address} : {props.global.contact.address}</p>
                 <p style={{textAlign: 'left', marginTop: -20}}>{props.global.defaults.lang.telephone} : {props.global.contact.telephone}</p>
                 <p style={{textAlign: 'left', marginTop: -20}}>{props.global.defaults.lang.email} : {props.global.contact.email}</p>
  
                </TextBlock>
                <FormContact content={props.content.formContact} defaults={props.global.defaults} design={props.global.design}/>
            </Col>
            <Col>
        
            </Col>
            {/* <div style={{marginTop: 50}}> */}
            <GoogleMaps headActive={props.global.header.headActive} lat={props.global.contact.latitude} lon={props.global.contact.longitude} name={props.global.contact.businessName}/>
            {/* </div> */}
            </Row>

            </Container>
        </>
    )
}

export default Contact