import React from 'react'
import { graphql } from "gatsby"
import Page from '../components/Pages/Page';
import Contact from '../components/Pages/Contact';


function ContactTemplate(props) {
    
    return (
        <Page {...props} PageComponent={ Contact } />
    )

}

export const queryContact = graphql`
fragment contactFields on NewSites {
    ...globalFields
    pages {
        type
        id
        aUri
        content {
            h1
            subTitle
            text
            image
            images
            seoTitle
            seoDescription
            formContact{
                field1
                field2
                field3
                field4
                field5
                button
                source
                theSubject
                to
            }
        }
    }
}
query contactQuery($subDomain: String) {
    newSites(subDomain: {eq: $subDomain}) {
        ...contactFields
    }
}`

export default ContactTemplate


