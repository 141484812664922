import React from "react";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";
import { device } from "../../utils";
import iconPin from "../../assets/image/svg/map-marker.svg";


const MapStyled = styled.div`
  width: 100%;
  height: 739px;
  margin-bottom: 50px;

  .pin {
    display: flex;
    align-items: center;
    width: 180px;
    color: var(--main-blue);
 
  }

  @media ${device.lg} {
    margin-top: ${props => props.marginTop};
    position: absolute;
    top: 0;
    right: 0;
    height: 739px;
    width: 47%;
  }
  @media (max-width: 992px){
    margin-top: 50px;
  }
`;

const LocationPin = ({name}) => (
  <div className="pin">
    <span style={{fontSize: 14}}>{name}</span>
    <img src={iconPin} className="pin-icon" alt="" />
  </div>
);

const renderLocationPin = (location) => {
 return (
  <LocationPin lat={location.lat} lng={location.lng} name={location.name}/>
 )
}


const MapGoogle = (props) => {



  const locations = [
    {lat: props.lat,lng: props.lon, name:props.name},
  ];

  return (
    <>
      <MapStyled marginTop={props.HeadActive === true ? '165px' : '130px'}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: `AIzaSyAI4VpVx83SVW1buTP7m09EIoLlADYQemo` }}
          defaultCenter={locations[0]}
          defaultZoom={12}
          className="h-100 w-100"
        >
          {locations.map(renderLocationPin)}
        </GoogleMapReact>
      </MapStyled>
    </>
  );
};

export default MapGoogle;
